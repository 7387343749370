/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 *
 */

import React, {
    FunctionComponent, Suspense, useEffect, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator,
} from '@mui/lab';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { TransactionEvent } from '../../../types/workflows';
import Button from '../../elements/Button';
import { ReactComponent as BackIcon } from '../../../assets/images/chevron-left.svg';
import { buildUrl } from '../../../utils/navigation';
import { AppRoute } from '../../../constants/routes';
import { formatDate } from '../../../utils/date';
import { comparePermissions, compareTransactions, compareProperties } from '../../../utils/validations';
import HasPermission from '../../elements/HasPermission';
import { Permissions } from '../../../types/permissions';
import { LoadingScreen } from '../LoadingScreen';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import { LoadingCircles } from '../../elements/LoadingCircles';

// Participants Icons
import { ReactComponent as PARTICIPANT_ADDED } from '../../../assets/images/PARTICIPANT_ADDED.svg';
import { ReactComponent as PARTICIPANT_INVITED } from '../../../assets/images/PARTICIPANT_INVITED.svg';
import { ReactComponent as PARTICIPANT_REMOVED } from '../../../assets/images/PARTICIPANT_REMOVED.svg';
import { ReactComponent as PARTICIPANT_ROLE_UPDATED } from '../../../assets/images/PARTICIPANT_ROLE_UPDATED.svg';
import { ReactComponent as PARTICIPANT_PERMISSIONS_UPDATED } from '../../../assets/images/PARTICIPANT_PERMISSIONS_UPDATED.svg';

// Contracts Icons
import { ReactComponent as CONTRACT_ATTACHED } from '../../../assets/images/CONTRACT_ATTACHED.svg';
import { ReactComponent as CONTRACT_DELETED } from '../../../assets/images/CONTRACT_DELETED.svg';
import { ReactComponent as CONTRACT_REPLACED } from '../../../assets/images/CONTRACT_REPLACED.svg';
import { ReactComponent as CONTRACT_SIGNED } from '../../../assets/images/CONTRACT_SIGNED.svg';
import { ReactComponent as CONTRACT_UPLOADED } from '../../../assets/images/CONTRACT_UPLOADED.svg';
import { ReactComponent as CONTRACT_VOIDED } from '../../../assets/images/CONTRACT_VOIDED.svg';
import { ReactComponent as SIGNER_SIGNED } from '../../../assets/images/SIGNER_SIGNED.svg';

// Documents Icons
import { ReactComponent as DOCUMENT_APPROVED } from '../../../assets/images/DOCUMENT_APPROVED.svg';
import { ReactComponent as DOCUMENT_DELETED } from '../../../assets/images/DOCUMENT_DELETED.svg';
import { ReactComponent as DOCUMENT_PENDING } from '../../../assets/images/DOCUMENT_PENDING.svg';
import { ReactComponent as DOCUMENT_REJECTED } from '../../../assets/images/DOCUMENT_REJECTED.svg';
import { ReactComponent as DOCUMENT_UPLOADED } from '../../../assets/images/DOCUMENT_UPLOADED.svg';
import { ReactComponent as DOCUMENT_PENDING_WITH_NOTES } from '../../../assets/images/DOCUMENT_PENDING_WITH_NOTES.svg';
import { ReactComponent as DOCUMENT_REJECTED_WITH_NOTES } from '../../../assets/images/DOCUMENT_REJECTED_WITH_NOTES.svg';

// Workflows Icons
import { ReactComponent as PROPERTY_UPDATED } from '../../../assets/images/PROPERTY_UPDATED.svg';
import { ReactComponent as TRANSACTION_CREATED } from '../../../assets/images/TRANSACTION_CREATED.svg';
import { ReactComponent as TRANSACTION_DELETED } from '../../../assets/images/TRANSACTION_DELETED.svg';
import { ReactComponent as TRANSACTION_OWNER_CHANGED } from '../../../assets/images/TRANSACTION_OWNER_CHANGED.svg';
import { ReactComponent as TRANSACTION_STATE_CHANGED } from '../../../assets/images/TRANSACTION_STATE_CHANGED.svg';
import { ReactComponent as TRANSACTION_TRANSFERRED } from '../../../assets/images/TRANSACTION_TRANSFERRED.svg';
import { ReactComponent as TRANSACTION_UPDATED } from '../../../assets/images/TRANSACTION_UPDATED.svg';

type OwnProps = TranslationContext & WorkflowContext;

const WorkflowHistoryScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getTransactionEvents,
    } = props;
   
    const { workflowId = '' } = useParams();
    const navigate = useNavigate();
    const [events, setEvents] = useState<TransactionEvent[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cursor, setCursor] = useState('');

    const fetchEvents = async (reset?: boolean) => {
        try {
            setIsLoading(true);
            const response = await getTransactionEvents(workflowId, reset ? '' : cursor);
            if (reset) {
                setEvents([...response.results || []]);
            } else {
                setEvents((prev) => [...prev, ...response.results || []]);
            }
           
            setCursor(response.cursor);
        } catch (error) {
            setEvents([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchEvents(true);
    }, []);

    const formatState = (event: TransactionEvent) => {
        let description: { key: string; value: string }[] | null = null;
    
        const title = (() => {
            switch (event.type) {
                case 'PROPERTY_UPDATED':
                    if (event.details.oldTransaction && event.details.newTransaction) {
                        const differences = compareProperties(event.details.oldTransaction, event.details.newTransaction, t);
                        if (differences.length > 0) {
                            description = differences;
                            
                            return t('workflows.history.PROPERTY_UPDATED');
                        }
                        return t('workflows.history.PROPERTY_UPDATED_NO_PARAMETERS');
                    }
                    break;

                case 'TRANSACTION_CREATED':
                    return t(`workflows.history.${event.type}`, {
                        transactionType: t(`workflows.list.filters.transactionTypeOptions.${event.details.newTransaction?.transactionType}`),
                    });
    
                case 'TRANSACTION_UPDATED':
                    if (event.details.oldTransaction && event.details.newTransaction) {
                        const differences = compareTransactions(event.details.oldTransaction, event.details.newTransaction, t);
                        if (differences.length > 0) {
                            description = differences;
                            
                            return t('workflows.history.TRANSACTION_UPDATED');
                        }
                        return t('workflows.history.TRANSACTION_UPDATED_NO_PARAMETERS');
                    }
                    break;
    
                case 'TRANSACTION_DELETED':
                    return t('workflows.history.TRANSACTION_DELETED');
    
                case 'TRANSACTION_STATE_CHANGED':
                    return t('workflows.history.TRANSACTION_STATE_CHANGED', {
                        oldStatus: t(`workflows.history.${event.details.oldStatus}`),
                        newStatus: t(`workflows.history.${event.details.newStatus}`),
                    });
    
                case 'TRANSACTION_TRANSFERRED':
                    return t('workflows.history.TRANSACTION_TRANSFERRED', {
                        oldOwner: event.details.oldOwner?.fullName,
                        newOwner: event.details.newOwner?.fullName,
                    });
    
                case 'PARTICIPANT_ADDED':
                    return `${t(`workflows.history.${event.type}`)}: ${event.details.newParticipant?.name}`;

                case 'PARTICIPANT_REMOVED':
                    return `${t(`workflows.history.${event.type}`)}: ${event.details.oldParticipant?.name}`;
    
                case 'PARTICIPANT_ROLE_UPDATED':
                    return `${t(`workflows.history.${event.type}`, { participantName: event.details.oldParticipant?.name })} '${
                        t(`participantRole.${event.details.oldParticipant?.participantRole}`)
                    }' ${t('workflows.history.to')} '${
                        t(`participantRole.${event.details.newParticipant?.participantRole}`)
                    }'`;
    
                case 'PARTICIPANT_PERMISSIONS_UPDATED':
                    return comparePermissions(event, t);
    
                case 'PARTICIPANT_INVITED':
                    return `${t(`workflows.history.${event.type}`)}: ${event.details.newParticipant?.name}`;
    
                case 'DOCUMENT_UPLOADED':
                    return `${t(`workflows.history.${event.type}`)}: ${event.details.newDocument?.name}`;
    
                case 'DOCUMENT_DELETED':
                    return t('workflows.history.DOCUMENT_DELETED', { documentName: event.details.oldDocument?.name });
    
                case 'DOCUMENT_APPROVED':
                    return t('workflows.history.DOCUMENT_APPROVED', {
                        documentName: event.details.oldDocument?.name,
                        userName: event.details.oldDocument?.lastUserInteractionName,
                    });
    
                case 'DOCUMENT_REJECTED_WITH_NOTES':
                    return t('workflows.history.DOCUMENT_REJECTED_WITH_NOTES', {
                        documentName: event.details.oldDocument?.name,
                        userName: event.details.oldDocument?.lastUserInteractionName,
                    });
    
                case 'DOCUMENT_PENDING_WITH_NOTES':
                    return t('workflows.history.DOCUMENT_PENDING_WITH_NOTES', {
                        documentName: event.details.oldDocument?.name,
                        userName: event.details.oldDocument?.lastUserInteractionName,
                    });
    
                case 'CONTRACT_UPLOADED':
                    return t('workflows.history.CONTRACT_UPLOADED', { contractName: event.details.oldContract?.name });
    
                case 'CONTRACT_DELETED':
                    return t('workflows.history.CONTRACT_DELETED', { contractName: event.details.oldContract?.name });
    
                case 'CONTRACT_ATTACHED':
                    return t('workflows.history.CONTRACT_ATTACHED', { contractName: event.details.oldContract?.name });
    
                case 'CONTRACT_SIGNED':
                    return t('workflows.history.CONTRACT_SIGNED', { contractName: event.details.oldContract?.name });
    
                case 'CONTRACT_VOIDED':
                    return t('workflows.history.CONTRACT_VOIDED', { contractName: event.details.oldContract?.name });
    
                case 'SIGNER_SIGNED':
                    return `${t(`workflows.history.${event.type}`)} '${event.details.newDocument?.name}'`;
    
                default:
            }
        })();

        return {
            id: event.id,
            title,
            description,
        };
    };

    const icons: Record<string, React.FC> = {
        PARTICIPANT_ADDED,
        PARTICIPANT_INVITED,
        PARTICIPANT_REMOVED,
        PARTICIPANT_ROLE_UPDATED,
        PARTICIPANT_PERMISSIONS_UPDATED,
    
        CONTRACT_ATTACHED,
        CONTRACT_DELETED,
        CONTRACT_REPLACED,
        CONTRACT_SIGNED,
        CONTRACT_UPLOADED,
        CONTRACT_VOIDED,
        SIGNER_SIGNED,
    
        DOCUMENT_APPROVED,
        DOCUMENT_DELETED,
        DOCUMENT_PENDING,
        DOCUMENT_REJECTED,
        DOCUMENT_UPLOADED,
        DOCUMENT_PENDING_WITH_NOTES,
        DOCUMENT_REJECTED_WITH_NOTES,
    
        PROPERTY_UPDATED,
        TRANSACTION_CREATED,
        TRANSACTION_DELETED,
        TRANSACTION_OWNER_CHANGED,
        TRANSACTION_STATE_CHANGED,
        TRANSACTION_TRANSFERRED,
        TRANSACTION_UPDATED,
    };

    const formatIcon = (event: TransactionEvent) => {
        const IconComponent = icons[event.type];
        return IconComponent ? <IconComponent data-testid={`${event.id}_${event.type}`} /> : null;
    };
    
    return (
        <Suspense fallback={<LoadingScreen />}>
            <HasPermission permissions={[Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, Permissions.MANAGE_ALL_ORGANIZATION_TRANSACTIONS]}>
                <DefaultLayout>
                    <div className="container">
                        <div className="button-container">
                            <Button
                                extraClasses="back-button"
                                startIcon={<BackIcon />}
                                onClick={() => navigate(buildUrl(AppRoute.ViewWorkflow, { workflowId }))}
                            >
                                {t('workflows.view.history')}
                            </Button>
                        </div>
                        {isLoading && <LoadingCircles size="m" variant="primary" />}
                        {!isLoading
                        && (
                            <InfiniteScrollWrapper
                                hasMore={!!cursor}
                                requestMore={fetchEvents}
                            >
                                <Timeline position="right">
                                    {events.map((event, index) => {
                                        const { id, title, description } = formatState(event);
                                        const isLastItem = index === events.length - 1;// Check if it's the last item
                                        const eventDetailsNotes = event.details?.notes;
                                        const eventNotes = event.notes;

                                        return (
                                            <TimelineItem key={id}>
                                                <TimelineOppositeContent />
                                                <TimelineSeparator>
                                                    <TimelineDot>
                                                        {formatIcon(event)}
                                                    </TimelineDot>
                                                    {!isLastItem && <TimelineConnector />} {/* Hide connector for the last item */}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <div className="timestamp">
                                                        <p>{formatDate(event.createdDate)}</p>
                                                    </div>
                                                    <span data-testid={`${event.id}_state`}>{title || ''}</span>
                                                    {description && (
                                                        <div className="description">
                                                            {description.map((item) => (
                                                                <p key={item.key}>{item.key} {item.value}</p>
                                                            ))}
                                                        </div>
                                                    )}
                                                    {eventDetailsNotes && (
                                                        <div className="notes">
                                                            <span data-testid={`${event.id}_event_notes`}>{t('workflows.history.notes')} {eventDetailsNotes}</span>
                                                        </div>
                                                    )}
                                                    {eventNotes && (
                                                        <div className="notes">
                                                            <span>{t('workflows.history.notes')} {eventNotes}</span>
                                                        </div>
                                                    )}
                                                    <div className="creator">
                                                        <p>
                                                            {t('workflows.history.updateBy')}: {event.user.fullName}
                                                        </p>
                                                    </div>
                                                </TimelineContent>
                                            </TimelineItem>
                                        );
                                    })}
                                </Timeline>
                            
                            </InfiniteScrollWrapper>
                        )
}
                    </div>
                </DefaultLayout>
            </HasPermission>
        </Suspense>
    );
};

export const WorkflowHistoryScreen = withTranslationContext(withWorkflowContext(WorkflowHistoryScreenBase));
